type ContentObj = {
  titleEn?: string | null;
  titleJa?: string | null;
  descriptionEn?: string | null;
  descriptionJa?: string | null;
  description_en?: string | null;
  description_ja?: string | null;
  additionalDetailJa?: string | null;
  additionalDetailEn?: string | null;
  messageJa?: string | null;
  messageEn?: string | null;
  nameEn?: string | null;
  nameJa?: string | null;
  reasonEn?: string | null;
  reasonJa?: string | null;
};

export type Language = "ja" | "en";
export type ContentType = "title" | "description" | "additionalDetail" | "message" | "name" | "reason";
type Key = `${ContentType}-${Language}`;

export type NoDataTextMap = Record<Language, string>;

const defaultNoDataTextMap: NoDataTextMap = {
  ja: "データが登録されていません",
  en: "No Data",
};

const convertEmptyToUndefined = (value: string | null | undefined): string | undefined => {
  if (value) return value;
  return undefined;
};

export const resolveLanguage = (
  obj: ContentObj,
  lang: Language | string,
  contentType: ContentType,
  noDataText: NoDataTextMap | string = defaultNoDataTextMap,
): string => {
  const noDataTextMap: NoDataTextMap =
    typeof noDataText === "string"
      ? {
          ja: noDataText,
          en: noDataText,
        }
      : noDataText;
  const map: Record<Key, string> = {
    "title-en": convertEmptyToUndefined(obj.titleEn) ?? convertEmptyToUndefined(obj.titleJa) ?? noDataTextMap.en,
    "title-ja": convertEmptyToUndefined(obj.titleJa) ?? convertEmptyToUndefined(obj.titleEn) ?? noDataTextMap.ja,
    "name-en": convertEmptyToUndefined(obj.nameEn) ?? convertEmptyToUndefined(obj.nameJa) ?? noDataTextMap.en,
    "name-ja": convertEmptyToUndefined(obj.nameJa) ?? convertEmptyToUndefined(obj.nameEn) ?? noDataTextMap.ja,
    "description-ja":
      convertEmptyToUndefined(obj.descriptionJa) ??
      convertEmptyToUndefined(obj.description_ja) ??
      convertEmptyToUndefined(obj.descriptionEn) ??
      noDataTextMap.ja,
    "description-en":
      convertEmptyToUndefined(obj.descriptionEn) ??
      convertEmptyToUndefined(obj.description_en) ??
      convertEmptyToUndefined(obj.descriptionJa) ??
      noDataTextMap.en,
    "additionalDetail-ja":
      convertEmptyToUndefined(obj.additionalDetailJa) ?? convertEmptyToUndefined(obj.additionalDetailEn) ?? noDataTextMap.ja,
    "additionalDetail-en":
      convertEmptyToUndefined(obj.additionalDetailEn) ?? convertEmptyToUndefined(obj.additionalDetailJa) ?? noDataTextMap.en,
    "message-ja": convertEmptyToUndefined(obj.messageJa) ?? convertEmptyToUndefined(obj.messageEn) ?? noDataTextMap.ja,
    "message-en": convertEmptyToUndefined(obj.messageEn) ?? convertEmptyToUndefined(obj.messageJa) ?? noDataTextMap.en,
    "reason-ja": convertEmptyToUndefined(obj.reasonJa) ?? convertEmptyToUndefined(obj.reasonEn) ?? noDataTextMap.ja,
    "reason-en": convertEmptyToUndefined(obj.reasonEn) ?? convertEmptyToUndefined(obj.reasonJa) ?? noDataTextMap.en,
  };
  const key = `${contentType}-${lang}` as Key;
  return map[key] || "";
};
