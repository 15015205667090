import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";

type FieldValue = string;

const HEIGHT = 32;

const StyledTextField = styled(TextField)(() => ({
  overflowY: "hidden",
  ".MuiInputBase-root": {
    height: HEIGHT,
    paddingLeft: 4,
    "&.Mui-focused fieldset": {
      borderWidth: 1,
    },
  },
  ".MuiOutlinedInput-input": {
    height: HEIGHT,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
}));

export type InlineTextFilterFieldProps = Pick<TextFieldProps, "id" | "placeholder" | "disabled" | "variant" | "InputProps"> & {
  name: string;
  disableOnBlur?: boolean;
  resetFieldAfterSubmit?: boolean;
};

const InlineTextFilterField: React.FC<InlineTextFilterFieldProps> = props => {
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { name, disableOnBlur, resetFieldAfterSubmit, InputProps, ...rest } = props;
  const { field } = useController<Record<string, FieldValue>>({
    name: name,
  });
  const [value, setValue] = React.useState(field.value);
  const [compositionState, setCompositionState] = React.useState<"PROCESSING" | "END">("END");

  const resetField = React.useCallback(() => {
    setValue("");
    field.onChange("");
  }, [field]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setValue("");
    });
    return () => {
      stop();
    };
  }, [onClearFields]);

  const textFieldProps: TextFieldProps = {
    size: "small",
    fullWidth: true,
    value: value,
    color: "secondary",
    inputRef: field.ref,
    onChange: event => {
      if (typeof event.target.value === "string") {
        setValue(event.target.value);
      }
    },
    onBlur: () => {
      if (disableOnBlur) {
        return;
      }
      field.onChange(value);
    },
    onKeyDown: event => {
      if (compositionState === "END" && event.key === "Enter") {
        field.onChange(event);
        if (resetFieldAfterSubmit) {
          resetField();
        }
      }
    },
    onCompositionStart: () => {
      setCompositionState("PROCESSING");
    },
    onCompositionEnd: () => {
      // For Safari, wait for end composition before handle keydown
      window.setTimeout(() => {
        setCompositionState("END");
      }, 100);
    },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
      endAdornment: (value || undefined) && (
        <IconButton
          disabled={props.disabled}
          onClick={() => {
            resetField();
          }}
        >
          <CancelIcon color={props.disabled ? "disabled" : "action"} />
        </IconButton>
      ),
      ...InputProps,
    },
    ...rest,
  };

  return <StyledTextField {...textFieldProps} />;
};

InlineTextFilterField.displayName = "InlineTextFilterField";

export default InlineTextFilterField;
