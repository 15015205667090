import { useTranslation } from "@hireroo/i18n";
import { QuestionSearchForm } from "@hireroo/validator";

import { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof QuestionSearchForm.QuestionSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  QUESTION_VARIANTS: "questionVariants",
  STATUSES: "statuses",
  DIFFICULTIES: "difficulties",
} satisfies Record<string, FieldKeyName>;

type QuestionTypeOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionSearchQuerySchema["questionVariants"][0];
  children?: QuestionTypeOption[];
};

type QuestionTypeFeature = "CHALLENGE" | "PROJECT" | "QUIZ" | "SYSTEM_DESIGN" | "FREEPAD";

export type QuestionTypesFieldArgs = {
  /**
   * default is challenge project quiz system_design
   */
  showingTargets: QuestionTypeFeature[];
};

export const useQuestionTypesField = (args: QuestionTypesFieldArgs): GroupMultiChoiceFieldProps => {
  const { t } = useTranslation();
  const { showingTargets } = args;
  const options: QuestionTypeOption[] = [];
  if (showingTargets.includes("CHALLENGE")) {
    options.push({
      value: "CHALLENGE",
      displayName: t("コーディング形式"),
      children: [
        { value: "CHALLENGE_ALGORITHM", displayName: t("アルゴリズム") },
        { value: "CHALLENGE_DATABASE", displayName: t("データベース") },
        { value: "CHALLENGE_CLASS", displayName: t("クラス") },
      ],
    });
  }
  if (showingTargets.includes("PROJECT")) {
    options.push({
      value: "PROJECT",
      displayName: t("実践形式"),
      children: [
        { value: "PROJECT_FRONTEND", displayName: t("フロントエンド") },
        { value: "PROJECT_BACKEND", displayName: t("バックエンド") },
        { value: "PROJECT_DATA_SCIENCE", displayName: t("データサイエンス") },
        { value: "PROJECT_OTHER", displayName: t("他") },
      ],
    });
  }
  if (showingTargets.includes("QUIZ")) {
    options.push({ value: "QUIZ", displayName: t("クイズ形式") });
  }
  if (showingTargets.includes("SYSTEM_DESIGN")) {
    options.push({ value: "SYSTEM_DESIGN", displayName: t("システムデザイン") });
  }

  if (showingTargets.includes("FREEPAD")) {
    options.push({ value: "FREEPAD", displayName: t("ライブコーディング") });
  }
  return {
    name: FieldName.QUESTION_VARIANTS,
    kind: "GROUP_MULTI_CHOICE",
    title: t("問題形式"),
    options: options,
  };
};

type StatusesOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "DRAFT", displayName: t("下書き") },
    { value: "REVIEWING", displayName: t("審査中") },
    { value: "REJECTED", displayName: t("差し戻し") },
    { value: "PUBLISHED", displayName: t("公開済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};

type DifficultiesOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionSearchQuerySchema["difficulties"][0];
};
export const useDifficultyField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: DifficultiesOption[] = [
    { value: "EASY", displayName: t("易しい") },
    { value: "MEDIUM", displayName: t("ふつう") },
    { value: "DIFFICULT", displayName: t("難しい") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("難易度"),
    name: FieldName.DIFFICULTIES,
    options: options,
  };
};
