import { useTranslation } from "@hireroo/i18n";
import { QuestionSearchForm } from "@hireroo/validator";

import type { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof QuestionSearchForm.QuestionSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  QUESTION_VARIANTS: "questionVariants",
  DIFFICULTIES: "difficulties",
} satisfies Record<string, FieldKeyName>;

type DifficultyOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionSearchQuerySchema["difficulties"][0];
};

export const useDifficultyField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: DifficultyOption[] = [
    { value: "EASY", displayName: t("易しい") },
    { value: "MEDIUM", displayName: t("ふつう") },
    { value: "DIFFICULT", displayName: t("難しい") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("難易度"),
    name: FieldName.DIFFICULTIES,
    options: options,
  };
};

type QuestionTypeOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionVariant;
  children?: QuestionTypeOption[];
};

export const useQuestionTypesField = (availableVariants: QuestionSearchForm.QuestionVariant[]): GroupMultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: QuestionTypeOption[] = [
    {
      value: "CHALLENGE",
      displayName: t("コーディング形式"),
      children: [
        { value: "CHALLENGE_ALGORITHM", displayName: t("アルゴリズム") },
        { value: "CHALLENGE_DATABASE", displayName: t("データベース") },
        { value: "CHALLENGE_CLASS", displayName: t("クラス") },
      ],
    },
    {
      value: "PROJECT",
      displayName: t("実践形式"),
      children: [
        { value: "PROJECT_FRONTEND", displayName: t("フロントエンド") },
        { value: "PROJECT_BACKEND", displayName: t("バックエンド") },
        { value: "PROJECT_DATA_SCIENCE", displayName: t("データサイエンス") },
        { value: "PROJECT_OTHER", displayName: t("その他") },
      ],
    },
    { value: "QUIZ", displayName: t("クイズ形式") },
    { value: "SYSTEM_DESIGN", displayName: t("システムデザイン形式") },
    { value: "FREEPAD", displayName: t("ライブコーディング") },
  ];
  return {
    name: FieldName.QUESTION_VARIANTS,
    kind: "GROUP_MULTI_CHOICE",
    title: t("問題形式"),
    options: options.filter(option => {
      return availableVariants.includes(option.value);
    }),
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};
