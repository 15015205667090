import * as z from "zod";

export const useTestResult = () => {
  return z.enum(["PASSED", "NOT_PASSED"]);
};

export type TestResult = z.infer<ReturnType<typeof useTestResult>>;

export const useTestEvaluationForm = () => {
  const result = useTestResult();
  return z.object({
    result: result.nullable(),
    comment: z.string(),
    agreement: z.boolean(),
  });
};

export type TestEvaluationFormSchema = z.infer<ReturnType<typeof useTestEvaluationForm>>;
