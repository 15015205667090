import * as z from "zod";

const QuestionVariant = z.enum([
  "CHALLENGE",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "FREEPAD",
  "PROJECT",
  "PROJECT_BACKEND",
  "PROJECT_FRONTEND",
  "PROJECT_DATA_SCIENCE",
  "PROJECT_OTHER",
  "QUIZ",
  "SYSTEM_DESIGN",
]);

export type QuestionVariant = z.infer<typeof QuestionVariant>;

const useQuestionVariants = () => {
  return z.array(QuestionVariant);
};

const useStatus = () => {
  return z.array(z.union([z.literal("DRAFT"), z.literal("REVIEWING"), z.literal("PUBLISHED"), z.literal("REJECTED")]));
};
export type StatusesSchema = z.infer<ReturnType<typeof useStatus>>;

const useDifficulties = () => {
  return z.array(z.union([z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]));
};
export type DifficultySchema = z.infer<ReturnType<typeof useDifficulties>>;

export const useQuestionSearchQuery = () => {
  const questionVariants = useQuestionVariants();
  const statuses = useStatus();
  const difficulties = useDifficulties();
  return z.object({
    questionVariants,
    statuses,
    difficulties,
    textFilter: z.string(),
  });
};

export type QuestionSearchQuerySchema = z.infer<ReturnType<typeof useQuestionSearchQuery>>;

//RemoteInterviewQuestionSearchForm

const useRemoteInterviewQuestionVariants = () => {
  return z.array(
    z.union([
      z.literal("CHALLENGE"),
      z.literal("CHALLENGE_ALGORITHM"),
      z.literal("CHALLENGE_DATABASE"),
      z.literal("CHALLENGE_CLASS"),
      z.literal("SYSTEM_DESIGN"),
      z.literal("FREEPAD"),
    ]),
  );
};

export type RemoteInterviewQuestionSchema = z.infer<ReturnType<typeof useRemoteInterviewQuestionVariants>>;

export const useRemoteInterviewQuestionSearchQuery = () => {
  const questionVariants = useRemoteInterviewQuestionVariants();
  const difficulties = useDifficulties();
  return z.object({
    questionVariants,
    difficulties,
    textFilter: z.string(),
  });
};
export type RemoteInterviewQuestionSearchQuerySchema = z.infer<ReturnType<typeof useRemoteInterviewQuestionSearchQuery>>;

export const useSortFieldValue = () => {
  return z.union([
    z.literal("accuracy-rate-descending"),
    z.literal("accuracy-rate-ascending"),
    z.literal("leak-score-descending"),
    z.literal("leak-score-ascending"),
    z.literal("average-elapsed-time-descending"),
    z.literal("average-elapsed-time-ascending"),
    z.literal("created-at-descending"),
    z.literal("created-at-ascending"),
    z.literal("num-uses-descending"),
    z.literal("num-uses-ascending"),
    z.literal("unknown-descending"),
    z.literal("unknown-ascending"),
  ]);
};
export type SortFieldValue = z.infer<ReturnType<typeof useSortFieldValue>>;
