import { useTranslation } from "@hireroo/i18n";

export const languageMapForDisplay: Record<string, string> = {
  python3: "Python (3.11.5)",
  go: "Go (1.15)",
  javascript: "JavaScript (node:14.16)",
  php: "PHP (7.4.15)",
  ruby: "Ruby (2.7.2)",
  typescript: "TypeScript (4.6.3)",
  c: "C (gcc 10.3.1)",
  cpp: "C++17 (gcc 10.3.1)",
  kotlin: "Kotlin (1.4.32)",
  java: "Java (15)",
  swift: "Swift (5.3.3)",
  scala: "Scala (2.13.1)",
  rust: "Rust (1.52.1)",
  dart: "Dart (2.13.4)",
  csharp: "C# (6.12.0)",
  sqlite: "SQLite (3.36.0)",
  pgsql: "PostgreSQL (13.3)",
  mysql: "MySQL (8.0.25)",
  perl: "Perl (5.38.2)",
};

export const languageMapForHighlight: Record<string, string> = {
  python3: "python",
  go: "go",
  javascript: "javascript",
  php: "php",
  ruby: "ruby",
  typescript: "typescript",
  c: "c",
  cpp: "cpp",
  kotlin: "kotlin",
  java: "java",
  swift: "swift",
  scala: "scala",
  rust: "rust",
  dart: "dart",
  csharp: "csharp",
  sqlite: "sql",
  pgsql: "pgsql",
  mysql: "mysql",
  perl: "perl",
};

export type LanguageMap = {
  value: string;
  displayName: string;
};

export const algorithmLanguageListMap: LanguageMap[] = [
  {
    value: "python3",
    displayName: "Python3",
  },
  {
    value: "go",
    displayName: "Go",
  },
  {
    value: "javascript",
    displayName: "JavaScript",
  },
  {
    value: "php",
    displayName: "PHP",
  },
  {
    value: "ruby",
    displayName: "Ruby",
  },
  {
    value: "typescript",
    displayName: "TypeScript",
  },
  {
    value: "c",
    displayName: "C",
  },
  {
    value: "cpp",
    displayName: "C++",
  },
  {
    value: "kotlin",
    displayName: "Kotlin",
  },
  {
    value: "java",
    displayName: "Java",
  },
  {
    value: "swift",
    displayName: "Swift",
  },
  {
    value: "scala",
    displayName: "Scala",
  },
  {
    value: "rust",
    displayName: "Rust",
  },
  {
    value: "dart",
    displayName: "Dart",
  },
  {
    value: "csharp",
    displayName: "C#",
  },
  {
    value: "perl",
    displayName: "Perl",
  },
];

export const sqlMapForDisplay: LanguageMap[] = [
  {
    value: "sqlite",
    displayName: "SQLite",
  },
  {
    value: "pgsql",
    displayName: "PostgreSQL",
  },
  {
    value: "mysql",
    displayName: "MySQL",
  },
];

export const classLanguageForDisplay: LanguageMap[] = [
  {
    value: "python3",
    displayName: "Python3",
  },
  {
    value: "javascript",
    displayName: "JavaScript",
  },
  {
    value: "php",
    displayName: "PHP",
  },
  {
    value: "ruby",
    displayName: "Ruby",
  },
  {
    value: "typescript",
    displayName: "TypeScript",
  },
  {
    value: "cpp",
    displayName: "C++",
  },
  {
    value: "kotlin",
    displayName: "Kotlin",
  },
  {
    value: "java",
    displayName: "Java",
  },
  {
    value: "swift",
    displayName: "Swift",
  },
  {
    value: "scala",
    displayName: "Scala",
  },
  {
    value: "rust",
    displayName: "Rust",
  },
  {
    value: "dart",
    displayName: "Dart",
  },
  {
    value: "csharp",
    displayName: "C#",
  },
  {
    value: "perl",
    displayName: "Perl",
  },
];

export type ChallengeQuestionVariant = "ALGORITHM" | "DATABASE" | "CLASS";

export const languageMapsForDisplay: Record<ChallengeQuestionVariant, LanguageMap[]> = {
  ALGORITHM: algorithmLanguageListMap,
  DATABASE: sqlMapForDisplay,
  CLASS: classLanguageForDisplay,
};

export const initialLanguageMap: Record<ChallengeQuestionVariant, string> = {
  ALGORITHM: "cpp",
  DATABASE: "mysql",
  CLASS: "cpp",
};

export const defaultLanguageMap: Record<ChallengeQuestionVariant, string> = {
  ALGORITHM: "python3",
  DATABASE: "mysql",
  CLASS: "python3",
};

export const BOOL_VALUE = {
  TRUE: "TRUE (1)",
  FALSE: "FALSE (0)",
};
export const boolDisplay: Record<string, string> = {
  0: BOOL_VALUE.FALSE,
  1: BOOL_VALUE.TRUE,
  "": "",
};
export const exampleMap: Record<string, string> = {
  string: '"hello world!"',
  int: "123",
  float: "1.23",
  bool: "true",
  sll: "[1,2,3]",
  bt: "[1,null,3]",
};

export const typeLabelMap: Record<string, string> = {
  string: "String",
  int: "Integer",
  float: "Float",
  bool: "Boolean",
};

export type Output = {
  expected: string | undefined;
  output: string;
  status: string;
  log?: string;
  isAccepted?: boolean;
  performance: number;
  maxMemory: number;
};

export const useAnswerLabelMap = () => {
  const { t } = useTranslation();
  const answerLabelMap: Record<string, string> = {
    BRUTE_FORCE: t("愚直解"),
    SUB_OPTIMAL: t("標準解"),
    OPTIMAL: t("最適解"),
  };

  return answerLabelMap;
};
