import { Payment } from "@hireroo/app-store/essential/employee";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateQuestionSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionSearchAreaPropsArgs): Widget.QuestionSearchAreaProps => {
  const currentSearchFilter = QuestionsStore.useCurrentSearchFilter();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const showingTargets = React.useMemo((): Widget.QuestionSearchAreaProps["questionShowingTarget"] => {
    const targets: Widget.QuestionSearchAreaProps["questionShowingTarget"] = ["CHALLENGE", "QUIZ"];
    if (isAvailableFeature("question.project.read")) {
      targets.push("PROJECT");
    }
    if (isAvailableFeature("question.systemDesign.read")) {
      targets.push("SYSTEM_DESIGN");
    }
    if (isAvailableFeature("question.freepad.read")) {
      targets.push("FREEPAD");
    }
    return targets;
  }, [isAvailableFeature]);
  const defaultValues: Widget.QuestionSearchAreaProps["defaultValues"] = {
    questionVariants: currentSearchFilter.questionVariants.slice(),
    statuses: currentSearchFilter.statuses.slice(),
    difficulties: currentSearchFilter.difficulties.slice(),
    textFilter: currentSearchFilter.textFilter,
  };
  return {
    disabled: false,
    defaultValues: defaultValues,
    onChange: fields => {
      QuestionsStore.updateCurrentSearchFilter(fields);
    },
    hasStatus: true,
    questionShowingTarget: showingTargets,
    variant: "STYLED",
  };
};
