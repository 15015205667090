import { QuestionSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type RemoteInterviewQuestionSearchAreaProps = {
  disabled: boolean;
  defaultValues: QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema;
  onChange: SubmitHandler<QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema>;
};

const RemoteInterviewQuestionSearchArea: React.FC<RemoteInterviewQuestionSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = QuestionSearchForm.useRemoteInterviewQuestionSearchQuery();
  const methods = useForm<QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const questionTypesField = PrivateHelper.useQuestionTypesField();
  const difficultyField = PrivateHelper.useDifficultyField();

  const changed = methods.watch();
  const [, setCachedValue] = React.useState(JSON.stringify(changed));
  React.useEffect(() => {
    const newValue = JSON.stringify(changed);
    setCachedValue(prev => {
      // Prevent double dispatching
      if (prev !== newValue) {
        methods.handleSubmit(onChange, console.warn)();
      }
      return newValue;
    });
  }, [methods, onChange, changed]);

  const searchForm: SearchFormProps = {
    kind: "MULTI_LINE",
    variant: "INHERIT",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [questionTypesField, difficultyField].flatMap(v => (v ? [v] : [])),
    onReset: () => {
      const resetValues: QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema = {
        questionVariants: [],
        textFilter: "",
        difficulties: [],
      };
      methods.reset(resetValues);
    },
  };

  return (
    <FormProvider {...methods}>
      <SearchForm {...searchForm} />
    </FormProvider>
  );
};

RemoteInterviewQuestionSearchArea.displayName = "RemoteInterviewQuestionSearchArea";

export default RemoteInterviewQuestionSearchArea;
