import { App, Company } from "@hireroo/app-store/essential/employee";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ResumeListContainer, { ResumeListContainerProps } from "./Container";

type Status = Resumes.SearchFilter["statuses"][0];

const statusMap: Record<Status, Graphql.ResumeStatus[]> = {
  /**
   * Since the ui shows "Created" and "Recalculating" as the same status, we need to search for both
   */
  CALCULATING: [Graphql.ResumeStatus.Created, Graphql.ResumeStatus.Recalculating],
  CALCULATED: [Graphql.ResumeStatus.Calculated],
  FAILED: [Graphql.ResumeStatus.Failed],
  EVALUATED: [Graphql.ResumeStatus.Evaluated],
};

export type ResumeListFetchContainerProps = {};

const ResumeListFetchContainer: React.FC<ResumeListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = Resumes.useInitialized();
  const currentSearchFilter = Resumes.useCurrentSearchFilter();
  const sortParams = Resumes.useSortParams();
  const pager = Resumes.usePager();
  const [result, refresh] = Graphql.useListResumesByCompanyIdForResumesQuery({
    variables: {
      input: {
        companyId: companyId,
        filters: {
          statuses:
            currentSearchFilter.statuses?.reduce<Graphql.ResumeStatus[]>((all, status) => {
              all.push(...statusMap[status]);
              return all;
            }, []) ?? undefined,
          name: currentSearchFilter.textFilter || undefined,
          result: currentSearchFilter.result !== null ? currentSearchFilter.result === "PASSED" : null,
          maxMatchingRate:
            currentSearchFilter.matchingRate.max !== Resumes.defaultFilters.matchingRate.max
              ? currentSearchFilter.matchingRate.max / 100
              : undefined,
          minMatchingRate:
            currentSearchFilter.matchingRate.min !== Resumes.defaultFilters.matchingRate.min
              ? currentSearchFilter.matchingRate.min / 100
              : undefined,
          skillTagIds: currentSearchFilter.skillTags.map(skillTag => skillTag.displayName),
          tagNames: currentSearchFilter.tags.map(tag => tag.displayName),
        },
        isDescending: sortParams.isDescending,
        offset: pager.offset,
        size: pager.size,
        sortMethod: sortParams.sortMethod,
        withCount: true,
      },
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      Resumes.initialize(result.data.resumesByCompanyId);
    }
  }, [result.data]);

  React.useEffect(() => {
    Resumes.setRefreshRes(refresh);
  }, [refresh]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ResumeListContainerProps = {};

  return <ResumeListContainer {...containerProps} />;
};

ResumeListFetchContainer.displayName = "ResumeListFetchContainer";

export default withErrorBoundary(ResumeListFetchContainer, {});
