import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";

const Description = styled(Typography)(() => ({
  whiteSpace: "pre-wrap",
}));

export type ListContentProps = {
  title: string;
  content: string;
  /** @default false */
  strong?: boolean;
  /** @default false */
  underline?: boolean;
  /** @default   alignItems?: "center" | "flex-start";
   */
  alignItems?: "center" | "flex-start";
};

const ListContent: React.FC<ListContentProps> = props => {
  const theme = useTheme();

  const descriptionStyle = React.useMemo((): TypographyProps["sx"] => {
    if (props.strong) {
      return {
        textDecoration: props.underline ? "underline" : undefined,
        fontSize: 20,
        fontWeight: 700,
      };
    }
    return {
      textDecoration: props.underline ? "underline" : undefined,
    };
  }, [props.strong, props.underline]);

  return (
    <ListItem style={{ padding: theme.spacing(0.5) }}>
      <ListItemText
        primary={
          <Stack direction="row" spacing="10px" sx={{ display: "flex", alignItems: props.alignItems ?? "flex-start" }}>
            <Box sx={{ width: 180, flexShrink: 0 }}>
              <Typography variant="body1">{props.title}</Typography>
            </Box>
            <Box>
              <Description variant="body1" sx={descriptionStyle}>
                <TextLinkify>{props.content}</TextLinkify>
              </Description>
            </Box>
          </Stack>
        }
        sx={{ margin: 0 }}
      />
    </ListItem>
  );
};

ListContent.displayName = "ListContent";

export default ListContent;
