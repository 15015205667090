import { useTranslation } from "@hireroo/i18n";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper, { PopperProps } from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const DEFAULT_HEIGHT = 30;

type Variant = "inline" | "multiline";

const HeightMap: Record<Variant, number> = {
  inline: 32,
  multiline: 30,
};

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  height: DEFAULT_HEIGHT,
  ".MuiButtonGroup-grouped": {
    minWidth: DEFAULT_HEIGHT,
  },
  ".MuiButtonGroup-grouped:not(:last-of-type)": {
    border: "none",
  },
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 5,
}));

const DefaultButton = styled(Button)(({ theme }) => ({
  ".MuiButton-root": {
    height: DEFAULT_HEIGHT,
  },
  textTransform: "none",
  fontSize: 13,
  borderColor: "transparent",
  color: theme.palette.text.primary,
}));

const ActiveButton = styled(Button)(({ theme }) => ({
  ".MuiButton-root": {
    height: DEFAULT_HEIGHT,
  },
  fontWeight: 400,
  fontSize: 13,
  textTransform: "none",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette["Secondary/Shades"].p30,
  borderColor: "transparent",
}));

const ActionButton = styled(Button)(() => ({
  height: 36,
}));

type Status = "ACTIVE" | "INACTIVE";

const MAX_TRUNC_LENGTH = 20;

export type SplitButtonProps = {
  variant?: "inline" | "multiline";
  disabled?: boolean;
  title: string;
  status: Status;
  label: Omit<ButtonProps, "children"> & { children: string };
  children?: React.ReactNode;
  onReset: () => void;
  onApply: () => void;
};

const SplitButton: React.FC<SplitButtonProps> = props => {
  const { t } = useTranslation();
  const { onReset, onApply } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const buttonHeight = HeightMap[props.variant ?? "multiline"];
  const StyledButtonMap = {
    INACTIVE: DefaultButton,
    ACTIVE: ActiveButton,
  } as const;
  const StyledButton = StyledButtonMap[props.status];
  const theme = useTheme();

  const buttonGroupProps: ButtonGroupProps = {
    variant: "text",
    ref: anchorRef,
    color: "secondary",
    size: "small",
    sx: {
      borderColor: props.status === "ACTIVE" ? theme.palette.Other.Divider : undefined,
      height: buttonHeight,
      ".MuiButtonGroup-grouped": {
        minWidth: buttonHeight,
      },
    },
    disabled: props.disabled,
  };

  const resetButtonProps: ButtonProps = {
    children: t("リセット"),
    variant: "outlined",
    color: "outline-only",
    onClick: () => {
      onReset();
      setOpen(false);
    },
  };

  const applyButtonProps: ButtonProps = {
    children: t("適用する"),
    variant: "contained",
    color: "secondary",
    onClick: () => {
      onApply();
      setOpen(false);
    },
  };

  const slicedLabelText =
    props.label.children.length < MAX_TRUNC_LENGTH ? props.label.children : props.label.children.slice(0, MAX_TRUNC_LENGTH) + "...";
  const labelButtonProps: ButtonProps = {
    ...props.label,
    color: "secondary",
    onClick: event => {
      setOpen(prevOpen => !prevOpen);
      props.label.onClick?.(event);
    },
    children: slicedLabelText,
    sx: {
      height: buttonHeight,
    },
  };

  const RightButtonChildrenMap: Record<Status, React.ReactNode> = {
    ACTIVE: <CloseOutlinedIcon fontSize="inherit" />,
    INACTIVE: <ArrowDropDownIcon fontSize="inherit" />,
  };

  const rightButtonProps: ButtonProps = {
    size: "small",
    "aria-controls": open ? "split-button-menu" : undefined,
    "aria-expanded": open ? "true" : undefined,
    "aria-label": "select merge strategy",
    "aria-haspopup": "menu",
    color: "secondary",
    onClick: () => {
      setOpen(false);
      onReset();
    },
    sx: {
      color: theme.palette.text.primary,
      width: buttonHeight,
    },
    children: RightButtonChildrenMap[props.status],
  };

  const popperProps: PopperProps = {
    sx: {
      zIndex: theme.zIndex.modal,
      maxWidth: "500px",
    },
    open: open,
    anchorEl: anchorRef.current,
    transition: true,
    disablePortal: false,
    /**
     * TODO @himenon Consult with Designer
     */
    placement: "bottom-start",
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    onApply();
  };

  const LabelButton: Record<Status, React.ReactNode> = {
    ACTIVE: (
      <>
        <StyledButton {...labelButtonProps} />
        <StyledButton {...rightButtonProps} />
      </>
    ),
    INACTIVE: <StyledButton {...labelButtonProps} endIcon={<ArrowDropDownIcon />} />,
  };

  return (
    <React.Fragment>
      <StyledButtonGroup {...buttonGroupProps}>{LabelButton[props.status]}</StyledButtonGroup>
      <Popper {...popperProps}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "top center",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box py={1.5} px={2} display="flex" justifyContent={"space-between"} justifyItems={"center"}>
                    <Typography variant="subtitle2" alignItems={"center"}>
                      {props.title}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box py={2} px={2}>
                    {props.children}
                  </Box>
                  <Divider />
                  <Box display={"flex"} justifyContent={"flex-end"} py={1} px={2}>
                    <Stack direction="row" spacing={2}>
                      <ActionButton {...resetButtonProps} />
                      <ActionButton {...applyButtonProps} />
                    </Stack>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

SplitButton.displayName = "SplitButton";

export default SplitButton;
