import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Split, { SplitProps } from "../../../../primitive/Split/Split";
import { useChallengeCodingEditorContext } from "../Context";
import EditorToolbar, { EditorToolbarProps } from "./EditorToolbar/EditorToolbar";
import IDEConsole, { IDEConsoleProps } from "./IDEConsole/IDEConsole";
import TabPanelBox from "./TabPanelBox";

const SplitParamsInEditorAndTestCasesSection = {
  splitId: "EditorAndTestCasesSection",
  Contents: {
    Editor: {
      id: "Editor",
    },
    Terminal: {
      id: "Terminal",
    },
  },
};

const EditorContentWrapper = styled(Box)(() => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
}));

const ConsoleWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

export type EditorAndTestCasesSectionProps = {
  console: IDEConsoleProps;
  /**
   * only for firebase editor
   */
  CodeEditors: {
    language: string;
    Content: React.ReactNode;
    ref?: React.Ref<unknown>;
  }[];
  /**
   * only for non firebase editor
   */
  codeEditor?: CodeEditorProps;
  editorToolbar: EditorToolbarProps;
};

const EditorAndTestCasesSection: React.FC<EditorAndTestCasesSectionProps> = props => {
  const { kind, selectedLanguage } = useChallengeCodingEditorContext();
  const editorRef = useResizeDetector();
  const splitProps: SplitProps = {
    splitId: SplitParamsInEditorAndTestCasesSection.splitId,
    splitDirection: "HORIZONTAL",
    items: [
      {
        id: SplitParamsInEditorAndTestCasesSection.Contents.Editor.id,
        Content: (
          <EditorContentWrapper key="editor" ref={editorRef.ref}>
            <EditorToolbar {...props.editorToolbar} />
            {kind === "ANSWER_SCREEN" &&
              props.CodeEditors.map(editor => (
                <TabPanelBox key={`editor-${editor.language}`} index={editor.language} value={selectedLanguage} ref={editor.ref}>
                  {editor.Content}
                </TabPanelBox>
              ))}
            {kind === "QUESTION_SCREEN" && (
              <React.Suspense>
                <CodeEditor width="100%" height={editorRef.height} {...props.codeEditor} />
              </React.Suspense>
            )}
          </EditorContentWrapper>
        ),
        size: {
          value: 100,
          unit: "%",
        },
        minSize: {
          value: 36,
          unit: "px",
        },
      },
      {
        id: SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id,
        Content: (
          <ConsoleWrapper key="console" display="flex" flexDirection="column" height="100%" maxHeight="100%" overflow="auto">
            <IDEConsole {...props.console} />
          </ConsoleWrapper>
        ),
        size: {
          value: 200,
          unit: "px",
        },
        minSize: {
          value: 36,
          unit: "px",
        },
        minimizedSize: {
          value: 36,
          unit: "px",
        },
        expandedSize: {
          value: 200,
          unit: "px",
        },
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" maxHeight="100%" width="100%" sx={{ overflowY: "hidden" }}>
      <Split {...splitProps} />
    </Box>
  );
};

EditorAndTestCasesSection.displayName = "EditorAndTestCasesSection";

export default EditorAndTestCasesSection;
