import { Resumes } from "@hireroo/app-store/page/e/resumes";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateResumeSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateResumeSearchAreaPropsArgs): Widget.ResumeSearchAreaProps => {
  const currentSearchFilter = Resumes.useCurrentSearchFilter();
  const tags = Resumes.useTags();
  const skillTagIds = Resumes.useSkillTagIds();
  const initializedCurrentSearchFilter = Resumes.useInitializedCurrentSearchFilter();

  const defaultValues = React.useMemo((): Widget.ResumeSearchAreaProps["defaultValues"] => {
    return {
      tags: currentSearchFilter.tags.slice(),
      skillTags: currentSearchFilter.skillTags.slice(),
      matchingRate: {
        min: currentSearchFilter.matchingRate.min,
        max: currentSearchFilter.matchingRate.max,
      },
      statuses: currentSearchFilter.statuses.slice(),
      result: currentSearchFilter.result,
      textFilter: currentSearchFilter.textFilter,
    };
  }, [currentSearchFilter]);

  return {
    disabled: !initializedCurrentSearchFilter,
    tagsField: {
      selectableTags: tags.map(tag => ({ displayName: tag.name })),
    },
    skillTagsField: {
      selectableTags: skillTagIds.map(skillTagId => ({ displayName: skillTagId })),
    },
    defaultValues: defaultValues,
    onChange: React.useCallback(fields => {
      Resumes.updateCurrentSearchFilter(fields);
      /**
       * clear selected resume ids to refresh state inside the list
       */
      Resumes.clearSelectedResumeIds();
    }, []),
  };
};
