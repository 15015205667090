import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import * as Graphql from "@hireroo/graphql/client/urql";

type UpdateQueryParamsCallback = (params: { [key: string]: string[] }) => void;

type SubscribeArgs = {
  updateQueryParams: UpdateQueryParamsCallback;
};

export const startSubscribe = (args: SubscribeArgs) => {
  return QuestionSearchArea.subscribeAllState(state => {
    const titleValues: string[] =
      state.filters.titleJa.length > 0 ? [state.filters.titleJa] : state.filters.titleEn.length > 0 ? [state.filters.titleEn] : [];
    const sortValues = state.sortMethod === Graphql.SortMethod.CreatedAt ? [`${Graphql.SortMethod.CreatedAt}-${state.isDescending}`] : [];
    args.updateQueryParams({
      [QuestionSearchArea.QueryKeys.QUESTION_VARIANT]: state.filters.questionVariants,
      [QuestionSearchArea.QueryKeys.TITLE]: titleValues,
      [QuestionSearchArea.QueryKeys.STATUS]: state.filters.statuses,
      [QuestionSearchArea.QueryKeys.DIFFICULTY]: state.filters.difficulties,
      [QuestionSearchArea.QueryKeys.SORT]: sortValues,
      // Set page number to "1" if it is not a page change
      [QuestionSearchArea.QueryKeys.PAGE]: state.page > 0 ? [state.page.toString()] : ["1"],
      [QuestionSearchArea.QueryKeys.SIZE]: state.size !== QuestionSearchArea.defaultState.size ? [state.size.toString()] : [],
    });
  });
};
