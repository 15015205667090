import { ref } from "valtio";

import { defaultPager } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.ListRes): void => {
  state.res = res;
  state.resumeIds = res.resumes.map(resume => resume.resumeId);
  res.resumes.forEach(resume => {
    state.resumeMap.set(resume.resumeId, resume);
  });
  updateRefreshKey();
};

export const clear = (): void => {
  state.res = null;
  state.currentSearchFilter = null;
  state.resumeMap.clear();
  state.resumeIds = [];
  state.selectedResumeIds = [];
  state.dialogStatus = "CLOSE";
  state.pager = defaultPager;
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};
export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const updateRefreshKey = () => {
  state.refreshKey = Math.random().toString(36);
};

export const clearSelectedResumeIds = () => {
  state.selectedResumeIds = [];
};

export const setTags = (resumeTags: Types.ResumeTags) => {
  state.resumeTags = resumeTags;
};

export const setSkillTags = (skillTags: Types.SkillTags) => {
  state.skillTags = skillTags;
};

export const setTagRefresh = (refresh: () => void) => {
  state.tagRefresh = ref(refresh);
};

export const tagRefresh = () => {
  if (state.tagRefresh) {
    state.tagRefresh();
  }
};

export const updateSelectedResumeIds = (resumeIds: Types.ResumeId[]) => {
  state.selectedResumeIds = resumeIds;
};

export const updateResume = (resume: Types.Resume) => {
  state.resumeMap.set(resume.resumeId, resume);
  if (state.res?.resumes) {
    const index = state.res.resumes.findIndex(r => r.resumeId === resume.resumeId);
    if (index !== -1) {
      state.res.resumes[index] = resume;
    }
  }
};

export const updateResumes = (resumes: Types.Resume[]) => {
  resumes.forEach(resume => {
    state.resumeMap.set(resume.resumeId, resume);
  });
};

export const deleteResumes = (resumeIds: Types.ResumeId[]) => {
  state.resumeIds = state.resumeIds.filter(id => !resumeIds.includes(id));
  resumeIds.forEach(id => {
    state.resumeMap.delete(id);
  });
  if (state.res?.resumes) {
    state.res.resumes = state.res.resumes.filter(resume => !resumeIds.includes(resume.resumeId));
  }
};

export const deleteResume = (resumeId: Types.ResumeId) => {
  deleteResumes([resumeId]);
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const setRefreshRes = (refresh: () => void) => {
  state.refreshRes = ref(refresh);
};
export const refreshRes = () => {
  if (state.refreshRes) {
    state.refreshRes();
  }
};

export const updateResumesAfterEvaluate = () => {
  clearSelectedResumeIds();
  updateRefreshKey();
  refreshRes();
};
