import { useTitle } from "@hireroo/app-helper/react-use";
import { Payment } from "@hireroo/app-store/essential/employee";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import QuestionSearchAreaContainer from "./widgets/QuestionSearchArea/Container";
import QuestionTableFetchContainer from "./widgets/QuestionTable/FetchContainer";

type CreateQuestionOptions = Pages.QuestionListProps["createDialog"]["items"];

export type GenerateQuestionListPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionListPropsArgs): Pages.QuestionListProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const initializedCurrentSearchFilter = QuestionsStore.useInitializedCurrentSearchFilter();
  const language = useCurrentLanguage();
  const [searchParams] = useSearchParams();
  useTitle(t("問題一覧"));

  const baseCreateQuestionOptionsProps = React.useMemo((): CreateQuestionOptions => {
    return [
      {
        headerText: `${t("コーディング")}&${t("データベース")}`,
        questionVariantButton: [
          {
            title: t("アルゴリズム"),
            description: t("候補者に問題に沿ったアルゴリズムの関数を実装してもらう問題形式です。"),
            kind: !isAvailableFeature("question.challenge.create") ? "DISABLED" : undefined,
            onClick: () => {
              navigate("/e/questions/challenge/create", {
                queryParams: {
                  type: "algorithm",
                },
              });
            },
          },
          {
            title: t("データベース"),
            description: t("候補者にSQLなどでDBの課題に沿って解いてもらう問題形式です。"),
            kind: !isAvailableFeature("question.challenge.create") ? "DISABLED" : undefined,
            onClick: () => {
              navigate("/e/questions/challenge/create", {
                queryParams: {
                  type: "database",
                },
              });
            },
          },
        ],
      },
      {
        headerText: t("クイズ形式"),
        questionVariantButton: [
          {
            title: t("クイズ"),
            description: t("複数の選択肢の中から正解を選ぶ問題形式です。"),
            kind: !isAvailableFeature("question.quiz.create") ? "DISABLED" : undefined,
            onClick: () => {
              navigate("/e/questions/quiz/create");
            },
          },
        ],
      },
    ];
  }, [isAvailableFeature, navigate, t]);

  const projectCreateOptions = React.useMemo((): CreateQuestionOptions => {
    return [
      {
        headerText: t("実践形式"),
        questionVariantButton: [
          {
            title: t("実践形式"),
            kind: "DISABLED",
            onClick: () => {
              // TODO: Please open When project question can create
              // navigate("/e/questions/project/create");
            },
          },
        ],
      },
    ];
  }, [t]);

  const freepadItems = React.useMemo((): CreateQuestionOptions => {
    return [
      {
        headerText: t("ライブコーディング"),
        questionVariantButton: [
          {
            title: t("コーディング"),
            description: t("候補者に問題に沿ったアルゴリズムの関数を実装してもらう問題形式です。"),
            onClick: () => {
              navigate("/e/questions/freepad/create", {
                queryParams: {
                  type: "algorithm",
                },
              });
            },
          },
          {
            title: t("システムデザイン"),
            description: t("対話式で、候補者に課題に沿ってシステムデザインをしてもらう問題形式です。"),
            onClick: () => {
              navigate("/e/questions/freepad/create", {
                queryParams: {
                  type: "systemDesign",
                },
              });
            },
          },
        ],
      },
    ];
  }, [navigate, t]);

  const createQuestionOptions = React.useMemo((): CreateQuestionOptions => {
    const options: CreateQuestionOptions = baseCreateQuestionOptionsProps;
    if (isAvailableFeature("question.freepad.create")) {
      options.push(...freepadItems);
    }
    if (isAvailableFeature("question.project.create")) {
      options.push(...projectCreateOptions);
    }
    return options;
  }, [baseCreateQuestionOptionsProps, freepadItems, isAvailableFeature, projectCreateOptions]);

  React.useEffect(() => {
    QuestionsStore.initializeFilters(
      {
        textFilter: searchParams.get(QuestionsStore.QueryKeys.TITLE) ?? "",
        statuses: PrivateHelper.convertStringsToStatuses(searchParams.getAll(QuestionsStore.QueryKeys.STATUS)),
        questionVariants: PrivateHelper.convertStringsToQuestionVariants(searchParams.getAll(QuestionsStore.QueryKeys.QUESTION_VARIANT)),
        difficulties: PrivateHelper.convertStringsToDifficulties(searchParams.getAll(QuestionsStore.QueryKeys.DIFFICULTY)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(QuestionsStore.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(QuestionsStore.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(QuestionsStore.QueryKeys.SORT)),
      },
    );
  }, [language, searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
        ],
        current: t("問題一覧"),
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    createButton: {
      disabled: [
        isAvailableFeature("question.challenge.create"),
        isAvailableFeature("question.quiz.create"),
        isAvailableFeature("question.freepad.create"),
      ].every(isAvailable => !isAvailable),
      title: t("お使いのプランではこの機能を利用できません"),
    },
    goQuestionPackageButton: {
      onClick: () => {
        navigate("/e/questions/packages");
      },
    },
    createDialog: {
      items: createQuestionOptions,
    },
    SearchArea: initializedCurrentSearchFilter ? <QuestionSearchAreaContainer /> : null,
    QuestionTable: initializedCurrentSearchFilter ? <QuestionTableFetchContainer /> : null,
  };
};
