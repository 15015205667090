import type * as Graphql from "@hireroo/graphql/client/urql";

export type State = {
  isDescending: boolean;
  page: number;
  size: number;
  sortMethod: Graphql.SortMethod;
  filters: Graphql.Filters;
};

// The keys of query params
export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  QUESTION_VARIANT: "questionVariant",
  DIFFICULTY: "difficulty",
  SORT: "sorting",
} as const;
export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
