import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";
const { ProjectContentsViewerV3 } = ProjectTestReport;

type ProjectDiffViewerProps = Extract<Widget.ProjectContentsViewerV3Props["viewer"], { mode: "DIFF" }>["content"];

export type GenerateProjectDiffViewerPropsArgs = {
  projectId: number;
};

export const useGenerateProjectDiffViewerProps = (args: GenerateProjectDiffViewerPropsArgs): ProjectDiffViewerProps => {
  const hooks = ProjectContentsViewerV3.createHooks(args.projectId);
  const diffSources = hooks.useDiffSources();
  const currentSelectedDiff = hooks.useCurrentDiff();
  return {
    status: "READY",
    diffImageSet:
      diffSources.from?.type === "IMAGE"
        ? {
            fromSrc: diffSources.from?.value ?? "",
            toSrc: diffSources.to?.value ?? "",
          }
        : undefined,
    diffEditor: {
      originalModelPath: `original-test-result-${currentSelectedDiff?.fromPath}`,
      original: diffSources?.from?.value ?? "",
      modifiedModelPath: `modified-test-result-${currentSelectedDiff?.toPath}`,
      modified: diffSources?.to?.value ?? "",
    },
  };
};
