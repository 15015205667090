import { useTranslation } from "@hireroo/i18n";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useSearchFormContext } from "../../PrivateContext";
import GroupMultiChoiceField, { GroupMultiChoiceFieldProps } from "../GroupMultiChoiceField/GroupMultiChoiceField";
import MultiChoiceField, { MultiChoiceFieldProps } from "../MultiChoiceField/MultiChoiceField";
import MultiChoiceV2Field, { MultiChoiceV2FieldProps } from "../MultiChoiceV2Field/MultiChoiceV2Field";
import RangeField, { RangeFieldProps } from "../RangeField/RangeField";
import SingleChoiceField, { SingleChoiceFieldProps } from "../SingleChoiceField/SingleChoiceField";
import TagSelectField, { TagSelectFieldProps } from "../TagSelectField/TagSelectField";
import TextFilterField, { TextFilterFieldProps } from "../TextFilterField/TextFilterField";

type MultiChoiceFieldWithKindProps = MultiChoiceFieldProps & { kind: "MULTI_CHOICE" };
type MultiChoiceFieldV2WithKindProps = MultiChoiceV2FieldProps & { kind: "MULTI_CHOICE_V2" };
type SingleChoiceFieldWithKindProps = SingleChoiceFieldProps & { kind: "SINGLE_CHOICE" };
type GroupChoiceFieldWithKindProps = GroupMultiChoiceFieldProps & { kind: "GROUP_MULTI_CHOICE" };
type TagSelectFieldWithKindProps = TagSelectFieldProps & { kind: "TAG" };
type RangeFieldWithKindProps = RangeFieldProps & { kind: "RANGE" };

type FieldProps =
  | MultiChoiceFieldWithKindProps
  | MultiChoiceFieldV2WithKindProps
  | GroupChoiceFieldWithKindProps
  | TagSelectFieldWithKindProps
  | RangeFieldWithKindProps
  | SingleChoiceFieldWithKindProps;

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
}));

const ResetButton = styled(Button)(({ theme }) => ({
  height: 30,
  color: theme.palette.text.primary,
  fontSize: 13,
}));

type Variant = "STYLED" | "INHERIT";

export type MultiLineSearchFormProps = {
  className?: string;
  disabled?: boolean;
  textFilter: TextFilterFieldProps;
  fields: FieldProps[];
  onReset?: () => void;
  /**
   *  variant of style. Default: STYLED
   */
  variant?: Variant;
  textFilterFieldWrapperProps?: Pick<BoxProps, "mb">;
  AdditionalFilter?: React.ReactNode;
};

const MultiLineSearchForm: React.FC<MultiLineSearchFormProps> = props => {
  const { t } = useTranslation();
  const { clearAllFields } = useSearchFormContext();
  const resetButtonProps: ButtonProps = {
    children: t("リセット"),
    startIcon: <RefreshOutlinedIcon fontSize="small" />,
    variant: "text",
    disabled: props.disabled,
    onClick: () => {
      clearAllFields();
      props.onReset?.();
    },
  };
  const variant = props.variant ?? "STYLED";
  const paperMap = {
    STYLED: {
      sx: {
        borderRadius: "16px",
        overflowX: "hidden",
        padding: "16px",
      },
      elevation: 2,
    },
    INHERIT: {
      elevation: 0,
      sx: { p: 0, bgcolor: "inherit" },
    },
  } satisfies Record<Variant, PaperProps>;

  return (
    <StyledPaper {...paperMap[variant]} className={props.className}>
      <Box mb={2} {...props.textFilterFieldWrapperProps}>
        <TextFilterField {...props.textFilter} disabled={props.disabled} />
      </Box>
      <Box display="flex" justifyContent="space-between" whiteSpace={"nowrap"}>
        <Box sx={{ overflowX: "auto", overflowY: "hidden", mr: 2, width: "100%", display: "inline-flex" }}>
          <Stack spacing={2} direction={"row"}>
            {props.fields.map((selectorProp, index) => {
              switch (selectorProp.kind) {
                case "GROUP_MULTI_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <GroupMultiChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "MULTI_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <MultiChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "MULTI_CHOICE_V2": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <MultiChoiceV2Field key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "SINGLE_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <SingleChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "TAG": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <TagSelectField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "RANGE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <RangeField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
              }
            })}
            {props.AdditionalFilter}
          </Stack>
        </Box>
        <ResetButton {...resetButtonProps} />
      </Box>
    </StyledPaper>
  );
};

MultiLineSearchForm.displayName = "MultiLineSearchForm";

export default MultiLineSearchForm;
