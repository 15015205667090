import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ChallengeCodingEditorFetchContainer, { ChallengeCodingEditorFetchContainerProps } from "./FetchContainer";

export type ChallengeCodingEditorInitialContainerProps = ChallengeCodingEditorFetchContainerProps;

const ChallengeCodingEditorInitialContainer: React.FC<ChallengeCodingEditorInitialContainerProps> = props => {
  const initialized = ChallengeCodingEditor.useInitialized(props.entityId);
  React.useEffect(() => {
    if (initialized) {
      ScreeningTestTutorial.autoStartTutorial("CHALLENGE");
    }
    return () => {
      ScreeningTestTutorial.clear();
    };
  }, [initialized]);
  if (!initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <ChallengeCodingEditorFetchContainer {...props} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorInitialContainer.displayName = "ChallengeCodingEditorInitialContainer";

export default withErrorBoundary(ChallengeCodingEditorInitialContainer, {});
