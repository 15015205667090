import { useSnapshot } from "valtio";

import { SortParams } from "./constants";
import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.res !== null;
};

export const useInitializedCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter !== null;
};

export const useSelectedResumeIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedResumeIds;
};

export const useSelectedResumes = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedResumeIds.map(id => {
    const resume = snapshot.resumeMap.get(id);
    if (!resume) {
      throw new Error(`resume not found: ${id}`);
    }
    return resume;
  });
};

export const useResumes = () => {
  const snapshot = useSnapshotState();
  return snapshot.resumeIds.map(id => {
    const resume = snapshot.resumeMap.get(id);
    if (!resume) {
      throw new Error(`resume not found: ${id}`);
    }
    return resume;
  });
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentSearchFilter) {
    throw new Error("CurrentSearchFilter is not initialized");
  }
  return snapshot.currentSearchFilter;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.resumeTags?.tags || [];
};
export const useSkillTagIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTags?.skillTagIds || [];
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useListResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not found");
  }
  return snapshot.res;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return SortParams[snapshot.pager.sortFieldValue];
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useRefreshKey = () => {
  const snapshot = useSnapshotState();
  return snapshot.refreshKey;
};
