import { useEnabledSuspiciousDegree } from "@hireroo/app-helper/feature";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Payment } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { formatScore } from "@hireroo/formatter/score";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { TestSearchForm } from "@hireroo/validator";
import * as React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import CsvDownloadContainer from "./widget/CsvDownload/Container";
import ScreeningTestListFetchContainer from "./widget/ScreeningTestList/FetchContainer";

export type GenerateScreeningTestListPropsArgs = {};

type SearchProps = Exclude<Pages.ScreeningTestListProps["search"], undefined>;
type MultipleChoiceOption = SearchProps["createdByField"]["options"][0];
type SelectableTag = SearchProps["tagsField"]["selectableTags"][0];
type Statuses = TestSearchForm.TestSearchQuerySchema["statuses"];
type RankList = TestSearchForm.TestSearchQuerySchema["rank"];
type ScreeningOptions = SearchProps["screeningField"]["options"];
type SuspiciousDegrees = TestSearchForm.TestSearchQuerySchema["suspiciousDegrees"];

export const useGenerateProps = (_args: GenerateScreeningTestListPropsArgs): Pages.ScreeningTestListProps => {
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const filters = ScreeningsTestsStore.useFilters();
  const navigate = useTransitionNavigate();
  const listFetchingStatus = ScreeningsTestsStore.useListFetchingStatus();
  const creators = ScreeningsTestsStore.useCreators();
  const initialized = ScreeningsTestsStore.useInitialized();
  const tags = ScreeningsTestsStore.useTags();
  const NotificationBannerContainer = useNotificationBanner();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const screenings = ScreeningsTestsStore.useScreenings();
  const enabledSuspiciousDegree = useEnabledSuspiciousDegree();

  const createdByFieldOptions: MultipleChoiceOption[] = React.useMemo(() => {
    return creators.reduce<MultipleChoiceOption[]>((acc, employee) => {
      const option: MultipleChoiceOption = {
        value: employee.id,
        displayName: employee.displayName || employee.email,
        iconSrc: employee.photoUrl,
      };

      if (employee.id === currentUser.uid) {
        return [option, ...acc];
      }

      return [...acc, option];
    }, []);
  }, [creators, currentUser.uid]);

  useTitle(t("候補者一覧"));
  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    active: initialized,
    search: {
      enabledStatisticsFilter: isAvailableFeature("test.rank.read"),
      enabledCreatedByFilter: true,
      enabledScreeningFilter: true,
      enabledSuspiciousDegreeFilter: enabledSuspiciousDegree && isAvailableFeature("test.suspicious-degree.read"),
      disabled: listFetchingStatus === "FETCHING",
      createdByField: {
        options: createdByFieldOptions,
      },
      screeningField: {
        options: screenings.map((screening): ScreeningOptions[0] => {
          return {
            value: screening.screeningId,
            displayName: screening.name,
          };
        }),
      },
      tagsField: {
        selectableTags: tags.map((tag): SelectableTag => {
          return {
            displayName: tag.name,
          };
        }),
      },
      defaultValues: {
        textFilter: filters.name,
        score: {
          min: typeof filters.minTotalScore === "number" ? formatScore(filters.minTotalScore) : 0,
          max: typeof filters.maxTotalScore === "number" ? formatScore(filters.maxTotalScore) : 100,
        },
        tags: filters.tagNames.map(tag => {
          return {
            displayName: tag,
          };
        }),
        screeningIds: filters.screeningIds.slice(),
        questionVariants: [],
        statuses: filters.statuses.reduce<Statuses>((all, status) => {
          const converted = PrivateHelper.statusToUi[status];
          if (converted !== null) {
            return all.concat(converted);
          }
          return all;
        }, []),
        createdBy: filters.createdByList.slice(),
        result: (() => {
          if (filters.result === undefined) {
            return null;
          }
          return filters.result ? "PASSED" : "NOT_PASSED";
        })(),
        rank: filters.rankEvaluations.reduce<RankList>((all, rank) => {
          const converted = PrivateHelper.rankToUi[rank];
          if (converted) {
            return all.concat(converted);
          }
          return all;
        }, []),
        suspiciousDegrees: (filters.suspiciousDegrees ?? []).reduce<SuspiciousDegrees>((all, suspiciousDegree) => {
          const converted = PrivateHelper.suspiciousDegreeToUi[suspiciousDegree];
          if (converted) {
            return all.concat(converted);
          }
          return all;
        }, []),
      },
      onChange: field => {
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningsTestsStore.clearSelectedSpotIds();
        ScreeningsTestsStore.updateFilters({
          name: field.textFilter,
          minTotalScore: field.score.min !== 0 ? field.score.min / 100 : undefined,
          maxTotalScore: field.score.max !== 100 ? field.score.max / 100 : undefined,
          minCustomScore: field.score.min !== 0 ? field.score.min / 100 : undefined,
          maxCustomScore: field.score.max !== 100 ? field.score.max / 100 : undefined,
          rankEvaluations: field.rank,
          createdByList: field.createdBy,
          statuses: field.statuses.map(status => PrivateHelper.statusToGraphql[status]),
          tagNames: field.tags.map(tag => tag.displayName),
          result: field.result ? PrivateHelper.resultMap[field.result] : undefined,
          screeningIds: field.screeningIds,
          suspiciousDegrees: field.suspiciousDegrees,
        });
        ScreeningsTestsStore.updateSearchTags(field.tags.map(tag => tag.displayName));
      },
    },
    TestList: <ScreeningTestListFetchContainer />,
    CsvDownload: <CsvDownloadContainer />,
  };
};
