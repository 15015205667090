import { ChallengePad } from "@hireroo/app-helper/hooks";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import * as React from "react";

import ChallengeCodingEditorContainer, { ChallengeCodingEditorContainerProps } from "./Container";

export type ChallengeCodingEditorMiddleContainerProps = {
  entityId: number;
  interviewKind: "INTERVIEW" | "DEMO";
  editorKind: "CANDIDATE" | "EMPLOYEE";
  uid: string;
  displayName: string;
  enableBrowserEventDetector: boolean;
  enabledWebSearch: boolean;
  enabledChatGPT: boolean;
};

const ChallengeCodingEditorMiddleContainer: React.FC<ChallengeCodingEditorMiddleContainerProps> = props => {
  const { entityId, interviewKind, enabledWebSearch, enabledChatGPT } = props;
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const { selectedLanguage, updateSelectedLanguage } = Widget.useChallengeCodingEditorContext();
  const entity = challengeEntityHooks.useEntity();
  const [result, startChallengeSessions] = Graphql.useStartChallengeSessionsForChallengeCodingEditorMutation();
  const { t } = useTranslation();
  const [sessionStatus, setSessionStatus] = React.useState<"INITIALIZING" | "INITIALIZED">("INITIALIZING");
  const [collaborativeState, collaborativeAction] = ChallengePad.useChallengeRealtimeDatabase({
    challengeId: entityId,
    isCandidate: props.editorKind === "CANDIDATE",
    isInterviewing: interviewKind === "INTERVIEW",
    selectedQuestionId: entity?.challengeQuestion?.questionId ?? 0,
    setSelectedLanguage: updateSelectedLanguage,
    selectedLanguage: selectedLanguage,
  });

  React.useEffect(() => {
    if (!enabledWebSearch && !enabledChatGPT) {
      setSessionStatus("INITIALIZED");
      return;
    }

    startChallengeSessions({
      input: {
        challengeId: entityId,
        language: collaborativeState.selectedLanguage,
        startWebSession: enabledWebSearch,
        startChatSession: enabledChatGPT,
      },
    })
      .then(res => {
        if (res.data?.startChallengeSessions) {
          ChallengeCodingEditor.updateChallengeSessions(entityId, res.data?.startChallengeSessions);
        }
      })
      .catch(e => {
        Snackbar.notify({
          severity: "error",
          message: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
        });
        Sentry.captureException(e);
      })
      .finally(() => {
        setSessionStatus("INITIALIZED");
      });
  }, [startChallengeSessions, entityId, collaborativeState.selectedLanguage, enabledWebSearch, enabledChatGPT, t, setSessionStatus]);

  const challengeCodingEditorContainerProps: ChallengeCodingEditorContainerProps = {
    ...props,
    collaborativeState,
    collaborativeAction,
  };

  // Show coding editor only when startChallengeSessions succeeded to ensure behavioral events are stored
  if (!collaborativeState.ready || sessionStatus === "INITIALIZING" || result.error) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <ChallengeCodingEditorContainer {...challengeCodingEditorContainerProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorMiddleContainer.displayName = "ChallengeCodingEditorMiddleContainer";

export default withErrorBoundary(ChallengeCodingEditorMiddleContainer, {});
