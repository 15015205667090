import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import { useCurrentLanguage } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { QuestionSearchForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

export type GenerateRemoteInterviewQuestionSearchAreaPropsArgs = {
  isLoading?: boolean;
};

export const useGenerateProps = (args: GenerateRemoteInterviewQuestionSearchAreaPropsArgs): Widget.RemoteInterviewQuestionSearchAreaProps => {
  const lang = useCurrentLanguage();

  const filters = QuestionSearchArea.useFilters();
  const filterText = lang === "en" ? filters.titleEn : filters.titleJa;

  const handleChange: SubmitHandler<QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema> = React.useCallback(
    fields => {
      QuestionSearchArea.updateFilters({
        titleEn: lang === "en" ? fields.textFilter : "",
        titleJa: lang === "ja" ? fields.textFilter : "",
        difficulties: fields.difficulties,
        questionVariants: fields.questionVariants,
      });
    },
    [lang],
  );

  return {
    onChange: handleChange,
    disabled: Boolean(args.isLoading),
    defaultValues: {
      questionVariants: filters.questionVariants as QuestionSearchForm.RemoteInterviewQuestionSchema,
      difficulties: filters.difficulties.filter(d => d !== "UNKNOWN") as QuestionSearchForm.DifficultySchema,
      textFilter: filterText,
    },
  };
};
