import * as Graphql from "@hireroo/graphql/client/urql";

import { State } from "./types";

export const initialFilters: Graphql.Filters = {
  difficulties: [],
  questionVariants: [],
  statuses: [],
  titleJa: "",
  titleEn: "",
};
export const defaultState: Readonly<State> = {
  isDescending: false,
  page: 0,
  size: 50,
  sortMethod: Graphql.SortMethod.Unknown,
  filters: initialFilters,
};
