import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import CLang from "@hireroo/ui-assets/images/Languages/C.svg";
import Cpp from "@hireroo/ui-assets/images/Languages/Cpp.svg";
import CSharp from "@hireroo/ui-assets/images/Languages/CSharp.svg";
import Dart from "@hireroo/ui-assets/images/Languages/Dart.svg";
import Go from "@hireroo/ui-assets/images/Languages/Go.svg";
import Java from "@hireroo/ui-assets/images/Languages/Java.svg";
import Javascript from "@hireroo/ui-assets/images/Languages/Javascript.svg";
import Kotlin from "@hireroo/ui-assets/images/Languages/Kotlin.svg";
import Mysql from "@hireroo/ui-assets/images/Languages/Mysql.png";
import Perl from "@hireroo/ui-assets/images/Languages/Perl.svg";
import Pgsql from "@hireroo/ui-assets/images/Languages/Pgsql.svg";
import Php from "@hireroo/ui-assets/images/Languages/Php.svg";
import Python3 from "@hireroo/ui-assets/images/Languages/Python3.svg";
import Ruby from "@hireroo/ui-assets/images/Languages/Ruby.svg";
import Rust from "@hireroo/ui-assets/images/Languages/Rust.svg";
import Scala from "@hireroo/ui-assets/images/Languages/Scala.svg";
import Sqlite from "@hireroo/ui-assets/images/Languages/Sqlite.svg";
import Swift from "@hireroo/ui-assets/images/Languages/Swift.svg";
import Typescript from "@hireroo/ui-assets/images/Languages/Typescript.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const languageIconMap: Record<string, string> = {
  python3: Python3,
  go: Go,
  javascript: Javascript,
  php: Php,
  ruby: Ruby,
  typescript: Typescript,
  c: CLang,
  cpp: Cpp,
  kotlin: Kotlin,
  java: Java,
  swift: Swift,
  scala: Scala,
  rust: Rust,
  dart: Dart,
  csharp: CSharp,
  perl: Perl,
  sqlite: Sqlite,
  pgsql: Pgsql,
  mysql: Mysql,
};

export type TestReportProgrammingLanguageItemProps = {
  runtime: string;
};

const TestReportProgrammingLanguageItem: React.FC<TestReportProgrammingLanguageItemProps> = props => {
  const { t } = useTranslation();
  return (
    <ListItem divider>
      <ListItemText
        primary={
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                {t("選択言語")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                <Typography variant="subtitle1">{languageMapForDisplay[props.runtime]}</Typography>
                <Box ml={2} />
                <img src={languageIconMap[props.runtime]} width={21} height={21} />
              </Box>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

TestReportProgrammingLanguageItem.displayName = "TestReportProgrammingLanguageItem";

export default TestReportProgrammingLanguageItem;
