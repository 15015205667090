import { useEnabledNewProjectEditor } from "@hireroo/app-helper/feature";
import { tuple } from "@hireroo/app-helper/tuple";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { createNode, FileNode, findNode, findParent, generateHashString, listChildPaths, removeNode } from "@hireroo/project/helpers/fileTree";
import { ProjectFileTreeV3 } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useImmer } from "use-immer";

// Use this inside of closure, such as setInterval function
// Thanks to: https://qiita.com/kgtkr/items/374e2b676c953f14ec99
const useValueRef = <T>(val: T): React.MutableRefObject<T> => {
  const ref = React.useRef<T>(val);
  React.useEffect(() => {
    ref.current = val;
  }, [val]);
  return ref;
};

const initialFileTree: FileNode = {
  id: ".",
  name: "/home/project/app",
  isDir: true,
  isRoot: true,
  value: "",
  children: {},
};

// syncMap will make sure consecutive update doesn't result flashy file update
// For example, when you update file a.txt 10 times within 1 sec, it only updates once, not 10 times
const syncMap: Record<string, NodeJS.Timeout> = {};

export type FileTreeArgs = {
  entityId: number;
  endpoint: string;
};

export const useFileTree = (args: FileTreeArgs) => {
  const enabledNewProjectEditor = useEnabledNewProjectEditor();

  const { endpoint } = args;
  const { useWorkspace, useAgentServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();
  const agentServerHealth = useAgentServerHealth();
  const isReady = workspace && agentServerHealth;

  const socketRef = useRef<ReconnectingWebSocket>();
  const [socketReady, setSocketReady] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [status, setStatus] = useState<"connecting" | "connected" | "disconnected">("connecting");

  const [fileTree, setFileTree] = useImmer<FileNode>(initialFileTree);
  const fileTreeRef = useValueRef<FileNode>(fileTree);

  const [selectedFile, setSelectedFile] = useState<string>();
  const selectedFileRef = useValueRef<string | undefined>(selectedFile);

  const [filesOpened, setFilesOpened] = useState<string[]>([]);
  const filesOpenedRef = useValueRef<string[]>(filesOpened);

  const [cwd, setCwd] = useState<string>("");

  // Awaiting queue holds awaiting messages to be acked by server
  const [awaitingBuffer, setAwaitingBuffer] = useImmer<Record<string, ProjectFileTreeV3.FsMessage>>({});
  const awaitingBufferRef = useValueRef<Record<string, ProjectFileTreeV3.FsMessage>>(awaitingBuffer);

  const ready = useMemo<boolean>(() => {
    return socketReady && initialized;
  }, [socketReady, initialized]);

  const selectFile = useCallback(
    (newSelectedFile: string) => {
      const node = findNode(fileTree, newSelectedFile);
      // If the node is not found or dir, we cannot continue the following process
      if (!node || node?.isDir) return;

      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FileDidOpenRequest = {
        id: msgId,
        type: "file/didOpen",
        params: {
          path: node.id,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [fileTree, setAwaitingBuffer],
  );

  const closeFile = useCallback(
    (closedFile: string) => {
      // If closed file isn't included in the opened files, do nothing
      if (!filesOpenedRef.current.includes(closedFile)) return;

      const node = findNode(fileTree, closedFile);
      // If the node is not found, we cannot continue the following process
      if (!node || node?.isDir) return;

      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FileDidCloseRequest = {
        id: msgId,
        type: "file/didClose",
        params: {
          path: node.id,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [setAwaitingBuffer, fileTree, filesOpenedRef],
  );

  const generateFilepath = useCallback(
    (at: string, name: string) => {
      let node = findNode(fileTree, at);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      // If the node is a file, new file can't be created, hence get the parent dir
      if (!node.isDir) {
        node = findParent(fileTree, node.id);
      }
      // If the parent node is not found, we cannot continue the following process
      if (!node) return;

      return node.id === "." ? name : `${node.id}/${name}`;
    },
    [fileTree],
  );

  const addFile = useCallback(
    (at: string, name: string) => {
      const path = generateFilepath(at, name);
      if (!path) return;

      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FileDidCreateRequest = {
        id: msgId,
        type: "file/didCreate",
        params: {
          path,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [generateFilepath, setAwaitingBuffer],
  );

  const addDir = useCallback(
    (at: string, name: string) => {
      let node = findNode(fileTree, at);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      // If the node is a file, new dir can't be created, hence get the parent dir
      if (!node.isDir) {
        node = findParent(fileTree, node.id);
      }
      // If the parent node is not found, we cannot continue the following process
      if (!node) return;

      const path = node.id === "." ? name : `${node.id}/${name}`;
      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.DirDidCreateRequest = {
        id: msgId,
        type: "dir/didCreate",
        params: {
          path,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [setAwaitingBuffer, fileTree],
  );

  const remove = useCallback(
    (id: string) => {
      const node = findNode(fileTree, id);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FileDidDeleteRequest | ProjectFileTreeV3.DirDidDeleteRequest = {
        id: msgId,
        type: node.isDir ? "dir/didDelete" : "file/didDelete",
        params: {
          path: node.id,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [setAwaitingBuffer, fileTree],
  );

  const sync = useCallback(
    (id: string) => {
      if (enabledNewProjectEditor) {
        // TODO @himenon worker経由で同期を取る場合に早期リターンをする
      }
      const node = findNode(fileTreeRef.current, id);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FileDidChangeRequest = {
        id: msgId,
        type: "file/didChange",
        params: {
          path: node.id,
          body: node.value,
        },
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    },
    [enabledNewProjectEditor, fileTreeRef, setAwaitingBuffer],
  );

  const update = useCallback(
    (id: string, value: string) => {
      if (enabledNewProjectEditor) {
        // TODO @himenon worker経由で同期を取る場合に早期リターンをする
      }
      setFileTree((draft: FileNode) => {
        const node = findNode(draft, id);
        if (node) {
          node.value = value;
        }
      });

      // Call sync 3 seconds after idling
      if (id in syncMap) {
        const timer = syncMap[id];
        clearTimeout(timer);
      }

      syncMap[id] = setTimeout(() => {
        sync(id);
      }, 3000);
    },
    [enabledNewProjectEditor, setFileTree, sync],
  );

  const initializeHandler = useCallback(
    (msg: ProjectFileTreeV3.InitializeRequest | ProjectFileTreeV3.InitializeResponse) => {
      // InitializeRequest doesn't come from the server, hence we just handle InitializeResponse
      const result = ProjectFileTreeV3.InitializeResponseSchema.safeParse(msg);
      if (result.success) {
        setCwd(result.data.params.cwd);
        setFileTree(draft => {
          draft.name = result.data.params.cwd;
          result.data.params.index.forEach(fileStat => {
            // Root directory can be ignored safely
            if (fileStat.path === ".") {
              return;
            }
            createNode(draft, fileStat.path, fileStat.is_dir, fileStat.is_read_only);
          });
        });

        setInitialized(true);
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [setFileTree],
  );

  const fileDidOpenHandler = useCallback(
    (msg: ProjectFileTreeV3.FileDidOpenRequest | ProjectFileTreeV3.FileDidOpenResponse) => {
      // FileDidOpenRequest doesn't come from the server, hence we just handle FileDidOpenResponse
      const result = ProjectFileTreeV3.FileDidOpenResponseSchema.safeParse(msg);
      if (result.success) {
        const node = findNode(fileTreeRef.current, result.data.params.path);
        // If the node is not found, we cannot continue the following process
        if (!node) return;

        setFileTree((draft: FileNode) => {
          const node = findNode(draft, result.data.params.path);
          if (node) {
            node.value = result.data.params.body;
          }
        });

        // If the file is closed, then open it, otherwise do nothing
        if (!filesOpenedRef.current.includes(node.id)) {
          setFilesOpened(prev => [...prev, node.id]);
        }
        setSelectedFile(node.id);
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [fileTreeRef, filesOpenedRef, setFileTree],
  );

  const fileDidCloseHandler = useCallback(
    (msg: ProjectFileTreeV3.FileDidCloseRequest | ProjectFileTreeV3.FileDidCloseResponse) => {
      // FileDidCloseRequest doesn't come from the server, hence we just handle FileDidCloseResponse
      const result = ProjectFileTreeV3.FileDidCloseResponseSchema.safeParse(msg);
      if (result.success) {
        const node = findNode(fileTreeRef.current, result.data.params.path);
        // If the node is not found, we cannot continue the following process
        if (!node) return;

        const newFilesOpened = filesOpenedRef.current.filter(file => file !== node.id);
        // Open the file asynchronously to avoid mui Tab error
        setTimeout(() => {
          setFilesOpened(newFilesOpened);
        });

        // If currently selected file is closed, then update it with the closest one
        if (selectedFileRef.current === node.id) {
          if (newFilesOpened.length > 0) {
            const index = filesOpenedRef.current.indexOf(node.id);
            setSelectedFile(newFilesOpened[Math.max(0, index - 1)]);
          } else {
            setSelectedFile(undefined);
          }
        }
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [fileTreeRef, filesOpenedRef, selectedFileRef],
  );

  const fileDidCreateHandler = useCallback(
    (msg: ProjectFileTreeV3.FileDidCreateRequest | ProjectFileTreeV3.FileDidCreateResponse, open?: boolean) => {
      // TODO: Separate this handler for request and response
      // FileDidCreateRequest and FileDidCreateResponse is the exact same type, hence we just handle one of them
      const result = ProjectFileTreeV3.FileDidCreateRequestSchema.safeParse(msg);
      if (result.success) {
        setFileTree(draft => {
          createNode(draft, result.data.params.path, false, false);
        });

        // Send a request again to open the newly created file
        if (open) {
          const msgId = generateHashString(16);
          const msg = {
            id: msgId,
            type: "file/didOpen",
            params: {
              path: result.data.params.path,
            },
          };

          setAwaitingBuffer(prev => {
            return {
              ...prev,
              [msgId]: msg,
            };
          });
          setTimeout(() => {
            socketRef.current?.send(JSON.stringify(msg));
          }, 10);
        }
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [setAwaitingBuffer, setFileTree],
  );

  const fileDidDeleteHandler = useCallback(
    (msg: ProjectFileTreeV3.FileDidDeleteRequest | ProjectFileTreeV3.FileDidDeleteResponse) => {
      // TODO: Separate this handler for request and response
      // FileDidDeleteRequest and FileDidDeleteResponse is the exact same type, hence we just handle one of them
      const params = msg.params;
      const result = ProjectFileTreeV3.FileDidDeleteResponseSchema.safeParse(msg);
      if (result.success) {
        const node = findNode(fileTreeRef.current, result.data.params.path);
        // If the node is not found, we cannot continue the following process
        if (!node) return;

        const newFilesOpened = filesOpenedRef.current.filter(file => file !== node.id);
        // If length differs, it means some opened files are deleted
        if (newFilesOpened.length !== filesOpenedRef.current.length) {
          setFilesOpened(newFilesOpened);
        }

        // If currently selected file is deleted, then update it with the closest one
        if (selectedFileRef.current === node.id) {
          if (newFilesOpened.length > 0) {
            const index = filesOpenedRef.current.indexOf(node.id);
            setSelectedFile(newFilesOpened[Math.max(0, index - 1)]);
          } else {
            setSelectedFile(undefined);
          }
        }

        setFileTree(draft => {
          removeNode(draft, node.id);
        });
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(params)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [setFileTree, fileTreeRef, filesOpenedRef, selectedFileRef],
  );

  const fileDidChangeHandler = useCallback(
    (msg: ProjectFileTreeV3.FileDidChangeRequest | ProjectFileTreeV3.FileDidChangeResponse, apply: boolean) => {
      if (!apply) return;

      // If it's a request, already returned, hence we just handle FileDidChangeResponse
      const result = ProjectFileTreeV3.FileDidChangeRequestSchema.safeParse(msg);
      if (result.success) {
        setFileTree(draft => {
          const node = findNode(draft, result.data.params.path);
          if (node) {
            node.value = result.data.params.body;
          }
        });
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [setFileTree],
  );

  const dirDidCreateHandler = useCallback(
    // TODO: Separate this handler for request and response
    // DirDidCreateRequest and DirDidCreateResponse is the exact same type, hence we just handle one of them
    (msg: ProjectFileTreeV3.DirDidCreateRequest | ProjectFileTreeV3.DirDidCreateResponse) => {
      const result = ProjectFileTreeV3.DirDidCreateResponseSchema.safeParse(msg);
      if (result.success) {
        setFileTree(draft => {
          createNode(draft, result.data.params.path, true, false);
        });
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [setFileTree],
  );

  const dirDidDeleteHandler = useCallback(
    (msg: ProjectFileTreeV3.DirDidDeleteRequest | ProjectFileTreeV3.DirDidDeleteResponse) => {
      // TODO: Separate this handler for request and response
      // DirDidDeleteRequest and DirDidDeleteResponse is the exact same type, hence we just handle one of them
      const result = ProjectFileTreeV3.DirDidDeleteResponseSchema.safeParse(msg);
      if (result.success) {
        const node = findNode(fileTreeRef.current, result.data.params.path);
        // node not found. we can't continue following process
        if (!node) return;

        // List child files as they also need to be closed
        const children = listChildPaths(node);
        const newFilesOpened = filesOpenedRef.current.filter(file => !children.includes(file));

        // If length differs, it means some opened files are deleted
        if (newFilesOpened.length !== filesOpenedRef.current.length) {
          setFilesOpened(newFilesOpened);
        }

        // Deleted file was opened previously, hence needs to change the state
        if (selectedFileRef.current && children.includes(selectedFileRef.current)) {
          if (newFilesOpened.length > 0) {
            const index = filesOpenedRef.current.indexOf(selectedFileRef.current);
            setSelectedFile(newFilesOpened[Math.min(Math.max(0, index - 1), newFilesOpened.length - 1)]);
          } else {
            setSelectedFile(undefined);
          }
        }

        setFileTree(draft => {
          removeNode(draft, node.id);
        });
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [fileTreeRef, setFileTree, filesOpenedRef, selectedFileRef],
  );

  const requestHandler = useCallback(
    (msg: ProjectFileTreeV3.FsMessage) => {
      switch (msg.type) {
        case "file/didCreate":
          fileDidCreateHandler(msg, false);
          break;
        case "file/didDelete":
          fileDidDeleteHandler(msg);
          break;
        case "file/didChange":
          fileDidChangeHandler(msg, true);
          break;
        case "dir/didCreate":
          dirDidCreateHandler(msg);
          break;
        case "dir/didDelete":
          dirDidDeleteHandler(msg);
          break;
      }

      const ackMsg: ProjectFileTreeV3.Ack = {
        id: msg.id,
        type: "ack",
        params: {},
      };

      socketRef.current?.send(JSON.stringify(ackMsg));
    },
    [fileDidCreateHandler, fileDidDeleteHandler, fileDidChangeHandler, dirDidCreateHandler, dirDidDeleteHandler],
  );

  const responseHandler = useCallback(
    (msg: ProjectFileTreeV3.FsMessage) => {
      const awaitingMessage = awaitingBufferRef.current[msg.id];
      if (!awaitingMessage) return;

      switch (msg.type) {
        case "initialize":
          initializeHandler(msg);
          break;
        case "file/didOpen":
          fileDidOpenHandler(msg);
          break;
        case "file/didClose":
          fileDidCloseHandler(msg);
          break;
        case "file/didCreate":
          fileDidCreateHandler(msg, true);
          break;
        case "file/didDelete":
          fileDidDeleteHandler(msg);
          break;
        case "file/didChange":
          fileDidChangeHandler(msg, false);
          break;
        case "dir/didCreate":
          dirDidCreateHandler(msg);
          break;
        case "dir/didDelete":
          dirDidDeleteHandler(msg);
          break;
      }

      setAwaitingBuffer(draft => {
        delete draft[msg.id];
      });
    },
    [
      awaitingBufferRef,
      dirDidCreateHandler,
      dirDidDeleteHandler,
      fileDidChangeHandler,
      fileDidCloseHandler,
      fileDidCreateHandler,
      fileDidDeleteHandler,
      fileDidOpenHandler,
      initializeHandler,
      setAwaitingBuffer,
    ],
  );

  const handleFileSyncMessage = useCallback(
    (e: MessageEvent) => {
      const result = ProjectFileTreeV3.FsMessageSchema.safeParse(JSON.parse(e.data));
      if (result.success) {
        // const msg = JSON.parse(e.data) as ProjectFileTreeV3.FsMessage;
        if (awaitingBufferRef.current[result.data.id]) {
          // If a response comes back from server,
          // it means that the change sent by client has accepted by server,
          // then apply the change to the editor according to its message type
          responseHandler(result.data);
        } else {
          // If a request comes in from server,
          // it means that some file change happened on the server,
          // then apply the change to the editor according to its message type
          requestHandler(result.data);
        }
      } else {
        throw new Error(`could not safely parse response: ${JSON.stringify(e.data)}, zod error: ${JSON.stringify(result.error)}\n`);
      }
    },
    [awaitingBufferRef, requestHandler, responseHandler],
  );

  useEffect(() => {
    if (!isReady) {
      return;
    }

    // Subscribe to file events so that they are in sync
    // Pins the server every 1 second, in case server doesn't respond
    const socket = new ReconnectingWebSocket(endpoint, [], {
      minReconnectionDelay: 1000,
      maxReconnectionDelay: 1000,
      reconnectionDelayGrowFactor: 1.0,
    });

    socket.onopen = () => {
      socketRef.current = socket;
      setSocketReady(true);
      setStatus("connected");
    };

    socket.onmessage = event => {
      handleFileSyncMessage(event);
    };

    socket.onclose = () => {
      setStatus("disconnected");
    };

    socket.onerror = event => {
      /**
       * If the communication is disconnected before the websocket connection is established, the following error occurs
       * """
       * WebSocket connection to '<URL>' failed: WebSocket is closed before the connection is established
       * """
       * This can occur due to the client's network environment and cannot be completely prevented. Therefore, give the client a chance to retry.
       * According to the internal implementation of reconnect-websocket, reconnection is performed after observing onerror.
       */
      if (event.error) {
        Sentry.captureException(event.error);
        Sentry.captureMessage(`Retry Url: ${socket.url}, Retry Count: ${socket.retryCount}`);
      }
    };

    return () => {
      socketRef.current?.close();
      socketRef.current = undefined;
      setSocketReady(false);
      setFileTree(initialFileTree);
      setInitialized(false);
      setStatus("connecting");
      setFilesOpened([]);
      setSelectedFile(undefined);
    };
  }, [setFileTree, endpoint, handleFileSyncMessage, isReady]);

  useEffect(() => {
    // Once connection has established, initialize file tree
    if (socketReady && !initialized) {
      const msgId = generateHashString(16);
      const msg: ProjectFileTreeV3.FsMessage = {
        id: msgId,
        type: "initialize",
        params: {},
      };

      setAwaitingBuffer(prev => {
        return {
          ...prev,
          [msgId]: msg,
        };
      });
      setTimeout(() => {
        socketRef.current?.send(JSON.stringify(msg));
      }, 10);
    }
  }, [setAwaitingBuffer, initialized, selectedFile, socketReady]);

  const state = {
    ready,
    status,
    fileTree,
    selectedFile,
    filesOpened,
    cwd,
  };

  const action = {
    generateFilepath,
    addFile,
    addDir,
    remove,
    update,
    selectFile,
    closeFile,
    reconnect: () => {
      socketRef.current?.reconnect();
    },
  };

  return tuple(state, action);
};
