import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useFilters = () => {
  const snapshot = useSnapshotState();
  return snapshot.filters;
};

export const useIsDescending = () => {
  const snapshot = useSnapshotState();
  return snapshot.isDescending;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.size;
};

export const useSortMethod = () => {
  const snapshot = useSnapshotState();
  return snapshot.sortMethod;
};
