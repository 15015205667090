import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Alert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MoreButton, { MoreButtonProps } from "../../primitive/Button/MoreButton/MoreButton";

const StyledAlert = styled(Alert)`
  border-radius: 8px;
  align-content: center;
`;

const IconMap: Record<AlertColor, React.ReactElement> = {
  success: <CheckCircleIcon color="success" />,
  info: <InfoIcon />,
  warning: <WarningAmberOutlinedIcon />,
  error: <ErrorIcon color="error" />,
};

type Severity = Exclude<Exclude<AlertProps["severity"], undefined>, "warning">;

export type StatusInfoProps = {
  severity: Severity;
  message: string;
  additionalInfo: {
    text: string;
    passedTime?: string;
  };
  editButton?: Pick<MoreButtonProps["options"][0], "onClick">;
};

const StatusInfo: React.FC<StatusInfoProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const alert: AlertProps = {
    variant: "filled",
    severity: props.severity,
    icon: IconMap[props.severity],
    sx: {
      mx: "auto",
      alignItems: "center",
      backgroundColor: theme.palette["Gray/Shades"].p8,
      color: theme.palette.text.secondary,
    },
  };

  if (props.severity === "info") {
    return (
      <StyledAlert
        {...alert}
        action={
          <Box display="inline-flex" mr={2} alignItems="center" justifyContent="center">
            <Typography fontSize={12} color="inherit">
              {props.additionalInfo.text}
            </Typography>
            {props.additionalInfo.passedTime && (
              <Typography ml={1} fontSize={12} fontWeight={600}>
                {props.additionalInfo.passedTime}
              </Typography>
            )}
          </Box>
        }
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Box>{props.message}</Box>
          <Box flexGrow={1} />
        </Stack>
      </StyledAlert>
    );
  }
  const resultMap: Record<Severity, string> = {
    success: t("合格"),
    error: t("不合格"),
    info: "",
  };

  const colorMap: Record<Severity, string> = {
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.text.secondary,
  };

  const moreButton: MoreButtonProps | undefined = props.editButton && {
    variant: "vertical",
    iconButton: {
      size: "small",
    },
    options: [
      {
        value: "edit",
        displayName: t("評価"),
        ...props.editButton,
      },
    ],
    color: "rgba(255, 255, 255, 0.56)",
  };

  return (
    <StyledAlert
      {...alert}
      action={
        <Box>
          <Box mr={0} display="flex" alignItems="right" justifyContent="right" ml="auto">
            {moreButton && <MoreButton {...moreButton} />}
          </Box>
          <Typography fontSize={12} color="inherit">
            {props.additionalInfo.text}
          </Typography>
        </Box>
      }
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box>
          <Typography fontWeight={700} color={colorMap[props.severity]}>
            {resultMap[props.severity]}
          </Typography>
        </Box>
      </Stack>
      <Box>{props.message}</Box>
    </StyledAlert>
  );
};

StatusInfo.displayName = "StatusInfo";

export default StatusInfo;
